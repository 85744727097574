<template>
  <div>
    <p class="text_home">
      <b>Libertad</b> es poder<br> hacer lo que<br> debemos.
    </p>
    <span class="italic_text">Montesquieu.</span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: "home",
  data: () => ({}),
  mounted() {
    this.changeToSecondPage(true);
  },
  methods: {
    ...mapMutations(['changeToSecondPage']),
  }
};
</script>

<style scoped>
.text_home {
  line-height: 23px;
}
</style>